<template>
  <div class="v-login">
    <div class="v-login__logo-wrapper">
      <TheLogo />
    </div>
    <div class="v-login__wrapper">
      <div class="v-login__text">
        <TheHeading level="h1" class="v-login__heading">{{ $t('LOGIN_HEADING') }}</TheHeading>
        <p class="v-login__subtitle" v-html="$t('LOGIN_TEXT')" />
        <TheButton class="v-login__btn" blue @click="login">
          {{ $t('LOGIN_BUTTON') }}
        </TheButton>
      </div>
      <div class="v-login__image-wrapper">
        <img class="v-login__image" src="/login-page.jpg" alt="Login page image" />
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import auth from '@/auth'
import TheButton from '@/components/TheButton'
import TheHeading from '@/components/TheHeading'
import TheLogo from '@/components/TheLogo'

export default {
  name: 'Login',

  components: {
    TheButton,
    TheHeading,
    TheLogo
  },

  setup() {
    const store = useStore()
    const router = useRouter()
    const login = () => auth.authorize({
      'redirect_url': window.hostname
    })
    onMounted(async () => {
      let resCA
      const user = await auth.checkToken()

      store.dispatch('user/setToken')
      store.dispatch('user/setUser', user)
      if (store.state.user.role === 'teacher') {
        resCA = await auth.checkAccess([process.env.VUE_APP_TEACHER_ACCESS_ID])
        if (resCA.products.some(product => product.id === process.env.VUE_APP_TEACHER_ACCESS_ID)) {
          store.dispatch('user/setTeacherAccess', true)
        }
      }
      router.push('/fag')
    })

    return {
      login
    }
  }
}
</script>

<style lang="scss">
.v-login__wrapper {
  max-width: 1070px;
  margin: 0 auto;
  padding: 1.5rem;

  @include bp(large) {
    display: flex;
    align-items: center;
  }
}

.v-login__logo-wrapper {
  padding: 1.5rem;

  @include bp(large) {
    margin: 2rem;
  }
}

.v-login__heading {
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 52.4px;
}

.v-login__subtitle {
  margin-top: 2rem;
  font-size: 1.25rem;

  ul {
    padding-left: 1.25rem;
  }
}

.v-login__image-wrapper,
.v-login__text {
  @include bp(large) {
    width: 50%;
  }
}

.v-login__text {
  @include bp-down(large) {
    margin-bottom: 4rem;
  }
}

.v-login__image {
  display: block;
  max-width: 390px;
}

.v-login__btn {
  margin-top: 2rem;
}
</style>
